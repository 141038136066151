<template>
  <PageContainer
    :title="title"
    :back="actions.back"
    :ellipsis="true"
    :submit="actions.submit"
    ref="pageContainer"
  >
    <template v-slot:content>
      <!-- loader -->
      <div class="page-segment" v-if="loading">
        <div class="page-segment-content" v-for="i in 5" :key="i">
          <div class="skeleton-container margin-top margin-bottom">
            <Skeleton width="20px" height="20px" borderRadius="16px" />
            <Skeleton width="90px" height="20px" borderRadius="16px" />
          </div>
          <div class="margin-bottom">
            <Skeleton width="100%" height="60px" borderRadius="16px" />
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="page-segment" v-if="showReviews">
        <div class="page-segment-content">
          <div class="heading-small margin-bottom" v-text="reviews.data.title" />
          <div class="container padding-y" v-for="(review, index) in reviews.data.options" :key="index">
            <!-- date -->
            <div class="date margin-bottom-sm">
              <div class="icon icon-recommend-on" />
              <div class="text content-title" v-text="review.date" />
            </div>
            <!-- content -->
            <div class="body" v-text="review.content" />
          </div>

          <LoaderSimple class="margin-y" v-if="scrollOptions && paginationLoader"/>
        </div>
      </div>

      <!-- datazero -->
      <div class="page-segment" v-if="showDatazero">
        <div class="page-segment-content datazero">
          <DataZeroSimple
            :text="datazero.text"
            :image="datazero.image"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  SpecialistProfile,
  Skeleton,
  LoaderSimple,
  NoReviews
} from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'

export default {
  name: 'Reviews',
  components: {
    PageContainer,
    Skeleton,
    LoaderSimple,
    DataZeroSimple
  },
  created () {
    this.getReviewsData(this.$route.params.id)
  },
  mounted () {
    const div = this.$refs.pageContainer.$el.childNodes[1]
    div.addEventListener('scroll', this.onScroll)
  },
  data () {
    return {
      reviews: null,
      loading: false,
      paginationLoader: false,
      scrollOptions: { maxTop: null, currentTop: null },
      getReviews: true,
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        },
        submit: {
          text: this.$translations['specialist-page'].button,
          loading: false,
          icon: 'icon-timer-calendar-off',
          iconLeft: true,
          iconSize: 'xl',
          callback: () => this.goSchedule()
        }
      },
      datazero: {
        text: this.$translations['specialist-page'].reviews.datazero,
        image: NoReviews
      },
      page: 1
    }
  },
  computed: {
    title () {
      return this.reviews?.data?.specialist || ''
    },
    showDatazero () {
      return this.reviews?.data?.options?.length === 0 && !this.loading
    },
    showReviews () {
      return this.reviews?.data?.options?.length > 0 && !this.loading
    }
  },
  methods: {
    getReviewsData (specialistId) {
      if (!this.reviews?.data?.options?.length) {
        this.loading = true
      } else {
        this.paginationLoader = true
      }
      SpecialistProfile.getReviews(specialistId, this.page, 100)
        .then((response) => {
          if (this.reviews?.data?.options?.length) {
            this.reviews.data.options = this.reviews.data.options.concat(response.data.options)
          } else {
            this.reviews = response
          }

          if (response.headers.page === response.headers.pages) {
            this.getReviews = false
          }
        })
        .finally(() => {
          this.loading = false
          this.paginationLoader = false
        })
    },
    onScroll (event) {
      this.scrollOptions.maxTop =
        event.target.scrollHeight - event.target.clientHeight
      this.scrollOptions.currentTop = event.target.scrollTop
    },
    goSchedule () {
      this.$router
        .push({
          name: 'Schedule',
          query: {
            specialistId: this.$route.params.id,
            back: this.$route.fullPath
          }
        })
    }
  },
  watch: {
    scrollOptions: {
      deep: true,
      handler: function (val1, val2) {
        if (this.getReviews && val2.currentTop === val1.maxTop) {
          this.page++
          this.getReviewsData(this.$route.params.id)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  border-bottom: 1px solid var(--gray-10)
.skeleton-container
  display: flex
  gap: 8px
.date
  display: flex
  gap: 8px
  .icon
    flex: none
    color: var(--purple)
    font-size: var(--xl)
    align-self: center
  .text
    text-transform: capitalize
    flex-grow: 1
    align-self: center
    color: var(--gray-80)
.datazero
  background: var(--gray-5)
  border-radius: 16px
</style>
